import React, { useState, useEffect } from 'react';
import SubscriptionService from '../services/SubscriptionService';
import List from './List';
import { Dropdown, DropdownButton, Spinner, InputGroup, FormControl } from 'react-bootstrap';
import '../styles/ListWithPagination.css';
import useDebounce from '../hooks/useDebounce';

export type Filters = {
  codigo_suscripcion: string;
  payment_option: string;
  recurrency: string;
  payment_method: string;
  subscr_status: string;
  cif_account: string;
  legal_name_account: string;
  descripcion: string;
};

interface Item {
  subscr_id: string;
  subscr_status: string;
  account_nif: string;
  account_legal_name: string;
  consultant_nif: string;
  consultant_legal_name: string;
  invoice_account_nif: string;
  invoice_account_legal_name: string;
  recurrency: string;
  invoicing_date: string | null;
  subscription_start_date: string | null;
  subscription_end_date: string | null;
  payment_method_code: string | null;
  payment_terms_code: string;
  item_number: string | null;
  item_description: string;
  pack_number: string | null;
  pack_description: string | null;
  is_pack: boolean;
  price_list: string | null;
  unit_price: number | null;
  line_discount_percentage: number | null;
  line_discount_amount: number | null;
  line_amount: number;
  remuneration_price: number | null;
  remuneration_discount_percentage: number | null;
  remuneration_discount_amount: number | null;
  remuneration_amount: number;
  invoicing_method: string;
  type: string;
  cancelled_at: string | null;
  cancellation_reason: string | null;
}

interface ListWithPaginationProps {
  filters: Filters;
}

const ListWithPagination: React.FC<ListWithPaginationProps> = ({ filters }) => {
  const [items, setItems] = useState<Item[]>([]);
  let [totalItemsF, setTotalItemsF] = useState(0);
  const [lastModified, setLastModified] = useState("");
  const [start, setStart] = useState(0);
  const [limit, setLimit] = useState(5);
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [pendingPage, setPendingPage] = useState(currentPage);
  const debouncedPage = useDebounce(pendingPage, 300);
  const [orderPayment, setOrderPayment] = useState<'ASC' | 'DESC'>('ASC');
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8000';
 
  const fetchData = async (start: number, limit: number, filters: Filters, orderPayment: 'ASC' | 'DESC') => {
    setLoading(true);
    setIsRefreshing(true);
    try {
      const data = await SubscriptionService.getAllSubscriptions(start, limit, filters, orderPayment);
      const { pagination, suscripciones } = data;
      const { totalPages: total, page, totalItems, lastModified} = pagination;
      setLastModified(lastModified);
      setTotalItemsF(totalItems);
      setItems(suscripciones);
      setTotalPages(total);
      setCurrentPage(page);
    } catch (error) {
      await handleLogout();
      console.error('Error fetching data:', error);
    } finally {
      setTimeout(() => setIsRefreshing(false), 500);
      setLoading(false);
    }
  };

  const handleLogout = async () => {
    const token = localStorage.getItem('authToken');
    const oauthLogoutUrl = process.env.REACT_APP_API_BASE_URL + '/auth/logout';
    const postLogoutRedirectUri = process.env.REACT_APP_REDIRECT_URI ? process.env.REACT_APP_REDIRECT_URI : "";

    if (token) {
      try {
        await fetch(`${apiBaseUrl}/auth/revoke`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        localStorage.removeItem('authToken');

        const logoutUrl = `${oauthLogoutUrl}?redirectUri=${encodeURIComponent(postLogoutRedirectUri)}`;
        window.location.href = logoutUrl;
      } catch (error) {
        console.error('Error cerrando la sesión:', error);
        localStorage.removeItem('authToken');
        window.location.href = postLogoutRedirectUri;
      }
    } else {
      console.warn('No hay token para cerrar sesión');
      window.location.href = postLogoutRedirectUri;
    }
  };

  useEffect(() => {
    fetchData(start, limit, filters, orderPayment);
  }, [start, limit, filters, orderPayment]);

  useEffect(() => {
    if (debouncedPage !== currentPage && debouncedPage >= 1 && debouncedPage <= totalPages) {
      setStart((debouncedPage - 1) * limit);
      setCurrentPage(debouncedPage);
    }
  }, [debouncedPage]);

  const handleLimitChange = (value: number) => {
    setLimit(value);
    setStart(0);
    setCurrentPage(1);
    setPendingPage(1);
  };

  const handlePageInputChange = (value: string) => {
    const pageNumber = Number(value);
    if (!isNaN(pageNumber)) {
      setPendingPage(pageNumber);
    }
  };

  const handleOrderChange = () => {
    setOrderPayment((prevOrder) => (prevOrder === 'ASC' ? 'DESC' : 'ASC'));
  };

  useEffect(() => {
    setStart(0);
    setCurrentPage(1);
  }, [filters]);

  return (
    <div className="list-with-pagination">
      {loading && (
        <div className="loading-overlay">
          <Spinner animation="border" role="status" variant="primary">
            <span className="visually-hidden">Cargando...</span>
          </Spinner>
        </div>
      )}
      <div className={`list-container ${isRefreshing ? 'refreshing' : ''}`}>
        {/* Se pasa el array de items como prop */}
        <List items={items} orderPayment={orderPayment} onOrderChange={handleOrderChange} totalItems={totalItemsF} lastModified={lastModified}/>
      </div>
      <div className="pagination-controls">
        <div className="page-selector">
          <InputGroup>
            <InputGroup.Text>Página</InputGroup.Text>
            <FormControl
              type="number"
              value={pendingPage}
              onChange={(e) => handlePageInputChange(e.target.value)}
              min="1"
              max={totalPages}
            />
            <InputGroup.Text>de {totalPages}</InputGroup.Text>
          </InputGroup>
        </div>
        <div className="items-per-page">
          <span className="items-label">Elementos por página:</span>
          <DropdownButton
            id="items-per-page-dropdown"
            title={`${limit}`}
            onSelect={(eventKey) => handleLimitChange(Number(eventKey))}
            variant="outline-secondary"
          >
            <Dropdown.Item eventKey="1">1</Dropdown.Item>
            <Dropdown.Item eventKey="5">5</Dropdown.Item>
            <Dropdown.Item eventKey="10">10</Dropdown.Item>
            <Dropdown.Item eventKey="20">20</Dropdown.Item>
            <Dropdown.Item eventKey="50">50</Dropdown.Item>
            <Dropdown.Item eventKey="100">100</Dropdown.Item>
          </DropdownButton>
        </div>
      </div>
    </div>
  );
};

export default ListWithPagination;
